<template>
  <div>
    <div class="middle-header">
      <page-title></page-title>
      <hr />
      <div v-if="searchType != 'order_number'">
        <item-filter
          @callback="searchItem"
          :consignmentOnly="true"
          :key="itemFilterKey"
        ></item-filter>
        <hr />
      </div>
      <div class="bar grid-x">
        <div class="medium-8 form">
          <div class="form-text">依</div>
          <div style="width: 145px">
            <select v-model="searchType">
              <option value="has_instock">良品庫存>0</option>
              <option value="all">自入倉以來</option>
              <option value="arrival_date">入倉時間</option>
              <option value="order_number">入倉單號</option>
            </select>
          </div>
          <div v-if="searchType == 'arrival_date'" style="width: 250px">
            <date-range-picker
              :range="range"
              :daysDisabledStart="daysDisabledStart"
              @callback="selectDate"
            ></date-range-picker>
          </div>
          <div v-if="searchType == 'order_number'">
            <input
              type="input"
              v-model="searchOrderNumbers"
              placeholder="EX：C20180401-001-1 , C20180405-001-1"
              style="width: 250px; height: 32px"
            />
            <button
              class="button primary small"
              type="button"
              @click="cleanSearch"
            >
              查詢
            </button>
          </div>
          <div class="form-text">查詢入倉商品目前狀態</div>
        </div>
        <div class="medium-4 text-right">
          <button
            class="button hollow small"
            type="button"
            @click="download"
            :disabled="disableDownloadButton"
          >
            <i class="fa fa-download" aria-hidden="true"></i>
            下載
          </button>
          <button
            class="button hollow small"
            type="button"
            @click="togglePhoto"
          >
            {{ showImageText }}
          </button>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div v-if="!searched" class="page-info">
        顯示
        <span class="page-number">{{ rowInfo }}</span>
        項 ( 共 {{ totalRow }} 項 )
      </div>
      <div v-else class="page-info">
        顯示
        <span class="page-number">{{ rowInfo }}</span>
        項 ( 搜尋結果共 {{ totalRow }} 項 )
        <button class="button hollow small" type="button" @click="cleanSearch">
          回到所有商品列表
        </button>
      </div>

      <NoSearchResult v-if="noResult"></NoSearchResult>
      <FloatingHeadTable
        v-show="!noResult"
        class="table-striped table-unborder"
      >
        <thead slot="thead">
          <tr class="group-head">
            <th :colspan="itemColspanColumn">商品資訊</th>
            <th colspan="2"></th>
            <th colspan="1">{{ searchDateText }}</th>
            <th colspan="8">目前狀態</th>
            <th></th>
          </tr>
          <tr>
            <th width="150" v-show="showPhoto">圖片</th>
            <th :class="getSortClass('full_name')" @click="sortBy('full_name')">
              商品名稱
            </th>
            <th
              width="85"
              :class="getSortClass('item_id')"
              @click="sortBy('item_id', 'number')"
            >
              媽咪愛
              <br />
              商品編號
            </th>
            <th width="100" :class="getSortClass('upc')" @click="sortBy('upc')">
              商品貨號
            </th>
            <th
              width="125"
              :class="getSortClass('barcode')"
              @click="sortBy('barcode')"
            >
              <span class="has-tip" v-tooltip="'國際條碼'">barcode</span>
            </th>
            <th width="110">
              <select name="days" id="days" v-model="salesDays">
                <option value="7">前7天銷量</option>
                <option value="14">前14天銷量</option>
                <option value="30">前30天銷量</option>
              </select>
            </th>
            <th
              width="95"
              :class="getSortClass('not_deliver_count')"
              @click="sortBy('not_deliver_count', 'number')"
            >
              客訂未出貨
            </th>
            <th
              width="123"
              :class="getSortClass('arrival_count')"
              @click="sortBy('arrival_count', 'number')"
            >
              已入倉總計
            </th>
            <th
              width="60"
              :class="getSortClass('warehouse_count')"
              @click="sortBy('warehouse_count', 'number')"
            >
              <span class="has-tip" v-tooltip="'媽咪愛倉庫庫存數量'">
                良品
                <br />
                庫存
              </span>
            </th>
            <th
              width="60"
              :class="getSortClass('flaw_count')"
              @click="sortBy('flaw_count', 'number')"
            >
              <span
                class="has-tip"
                v-tooltip="'瑕疵的商品數量 & 需退回給供應商的商品'"
              >
                瑕疵
                <br />
                庫存
              </span>
            </th>
            <th
              width="71"
              :class="getSortClass('not_bill_count')"
              @click="sortBy('not_bill_count', 'number')"
            >
              <span
                class="has-tip"
                v-tooltip="'已出貨給消費者的數量(數量可能因消費者退貨調整)'"
              >
                已出貨
                <br />
                未請款
              </span>
            </th>
            <th
              width="85"
              :class="getSortClass('days_in_stock')"
              @click="sortBy('days_in_stock', 'number')"
            >
              <span
                class="has-tip"
                v-tooltip="'商品已在庫之天數，以現有庫存最早入倉日開始計算'"
              >
                在庫天數
              </span>
            </th>
            <th
              width="85"
              :class="getSortClass('days_no_movement')"
              @click="sortBy('days_no_movement', 'number')"
            >
              <span class="has-tip" v-tooltip="'此商品已有多少天沒有售出'">
                滯銷天數
              </span>
            </th>
            <th
              width="90"
              :class="getSortClass('days_nearest_expiry')"
              @click="sortBy('days_nearest_expiry', 'number')"
            >
              <span class="has-tip" v-tooltip="'現有庫存中效期最早的日期'">
                最早效期
              </span>
            </th>
            <th
              width="71"
              :class="getSortClass('billed_count')"
              @click="sortBy('billed_count', 'number')"
            >
              已出貨
              <br />
              已請款
            </th>
            <th
              width="71"
              :class="getSortClass('refunded_count')"
              @click="sortBy('refunded_count', 'number')"
            >
              已退倉
            </th>
            <th
              width="71"
              :class="getSortClass('item_unarrival_count')"
              @click="sortBy('item_unarrival_count', 'number')"
            >
              <span
                class="has-tip left"
                v-tooltip="'媽咪愛已下入倉單，商品出貨中未進媽咪愛倉庫'"
              >
                已下單
                <br />
                未入倉
              </span>
            </th>
            <th
              width="71"
              :class="getSortClass('refunding_count')"
              @click="sortBy('refunding_count', 'number')"
            >
              待退倉
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in pageItems" :key="item.item_id">
            <td v-show="showPhoto">
              <img width="150" height="150" :src="item.image_url" />
            </td>
            <td>{{ item.full_name }}</td>
            <td>{{ item.item_id }}</td>
            <td class="word-break">{{ item.upc }}</td>
            <td class="word-break">{{ item.barcode }}</td>
            <td>{{ sales[item.item_id] }}</td>
            <td>{{ item.not_deliver_count }}</td>
            <td>
              <a
                v-if="item.arrival_count > 0"
                @click="arrivalRecord(item)"
                class="has-tip"
                v-tooltip="'點擊查看入倉紀錄'"
              >
                {{ item.arrival_count }}
              </a>
              <span v-else>{{ item.arrival_count }}</span>
            </td>
            <td>{{ item.warehouse_count }}</td>
            <td>{{ item.flaw_count }}</td>
            <td>{{ item.not_bill_count }}</td>
            <td>
              <span
                :class="{
                  'text-warning': item.days_in_stock > 60,
                  'text-danger': item.days_in_stock > 90,
                }"
              >
                {{ item.days_in_stock || 0 }}
              </span>
            </td>
            <td>
              <span
                :class="{
                  'text-warning': item.days_no_movement > 60,
                  'text-danger': item.days_no_movement > 90,
                }"
              >
                {{ item.days_no_movement || 0 }}
              </span>
            </td>
            <td>
              <span
                :class="{
                  'text-warning': isNearExpiry(item.nearest_expiry_date, 30),
                  'text-danger': isNearExpiry(item.nearest_expiry_date, 15),
                }"
              >
                {{ formatDate(item.nearest_expiry_date) }}
              </span>
            </td>
            <!-- 原有欄位繼續 -->
            <td>{{ item.billed_count }}</td>
            <td>
              <a
                v-if="item.refunded_count > 0"
                @click="refundRecord(item)"
                class="has-tip"
                v-tooltip="'點擊查看退倉紀錄'"
              >
                {{ item.refunded_count }}
              </a>
              <span v-else>{{ item.refunded_count }}</span>
            </td>
            <td>{{ item.item_unarrival_count }}</td>
            <td>{{ item.refunding_count }}</td>
          </tr>
        </tbody>
      </FloatingHeadTable>

      <pagination
        :key="paginationKey"
        :totalRow="this.totalRow"
        @setPage="setPage"
      ></pagination>
    </div>
    <Modal v-if="showModal" :title="recordTitle" @close="closeModal">
      <records
        v-if="recordItem != null"
        :searchConditions="recordSearchConditions"
      ></records>
    </Modal>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import request from '@/legacy/utils/request'
import qs from 'query-string'

import PageTitle from '@/legacy/components/PageTitle/PageTitle.vue'
import ItemFilter from '@/legacy/components/ItemFilter.vue'
import DateRangePicker from '@/legacy/components/DateRangePicker.vue'
import NoSearchResult from '@/legacy/components/NoSearchResult.vue'
import Pagination from '@/legacy/components/Pagination.vue'
import Modal from '@/legacy/components/Modal.vue'
import FloatingHeadTable from '@/legacy/components/Table/FloatHeaderTable.vue'
import Records from './components/WarehousingRecords.vue'
import Cookie from '@/legacy/utils/cookie'
import SorterMixin from '@/legacy/mixins/sorter.js'

export default {
  data() {
    return {
      items: [],
      sales: {},
      showPhoto:
        (Cookie.get('preference_show_item_photo') || 'true') === 'true',
      salesDays: 7,
      itemFilterKey: 0, // for refresh
      searchedItemIds: [],
      searched: false,
      pageFirstRow: 0,
      pageLastRow: 0,
      paginationKey: 0, // change to re-render pagination
      showModal: false,
      recordItem: null,
      recordType: '', // arrival, refund
      searchType: 'has_instock',
      searchOrderNumbers: '',
      range: {
        startDate: moment().subtract(1, 'months').startOf('month'),
        endDate: moment().subtract(1, 'months').endOf('month'),
      },
      daysDisabledStart: moment().add(1, 'days'),
      disableDownloadButton: false,
    }
  },

  mounted() {
    this.initSortData('items', 'full_name', 'asc', 'string', 'full_name')
    this.fetchStockData()
  },

  mixins: [SorterMixin],
  watch: {
    items() {
      this.paginationKey += 1
    },
    salesDays() {
      this.fetchSalesData()
    },
    searchType(value) {
      if (value != 'order_number') {
        this.fetchStockData()
      }
    },
  },

  computed: {
    itemColspanColumn() {
      return this.showPhoto ? 5 : 4
    },
    showImageText() {
      return this.showPhoto ? '隱藏圖片' : '顯示圖片'
    },
    searchDateText() {
      if (this.searchType == 'arrival_date') {
        return (
          this.range.startDate.format('YYYY/M/D') +
          '-' +
          this.range.endDate.format('M/D')
        )
      } else if (this.searchType == 'has_instock') {
        return '良品庫存>0'
      } else {
        return '自入倉以來'
      }
    },
    totalRow() {
      return this.items.length
    },
    rowInfo() {
      var text = this.pageFirstRow
      if (this.pageLastRow > 0) {
        text += ' - ' + this.pageLastRow
      }
      return text
    },
    pageItems() {
      return this.items.slice(this.pageFirstRow - 1, this.pageLastRow)
    },
    noResult() {
      return (
        this.totalRow == 0 &&
        (this.searched || this.searchType == 'arrival_date')
      )
    },
    recordTitle() {
      if (this.recordType == 'arrival') {
        return '入倉紀錄'
      }
      if (this.recordType == 'refund') {
        return '退倉紀錄'
      }
      return ''
    },
    recordSearchConditions() {
      return {
        recordItem: this.recordItem,
        recordType: this.recordType,
        searchType: this.searchType,
        range: this.range,
        searchOrderNumbers: this.searchOrderNumbers,
      }
    },
  },

  methods: {
    fetchStockData() {
      this.$store.dispatch('startLoading')
      request
        .get('stock/consignment_stock', {
          params: this.queryParams(),
        })
        .then(({ data }) => {
          this.items = data.data.map((item) => ({
            ...item,
            days_no_movement: item.days_no_movement || 0,
            days_nearest_expiry: item.days_nearest_expiry || 0,
            days_in_stock: item.days_in_stock || 0,
            nearest_expiry_date: item.nearest_expiry_date || '0000-00-00',
          }))

          if (this.totalRow == 0) {
            return
          }
          this.sortItems()
          this.fetchSalesData()
        })
        .finally(() => {
          this.$store.dispatch('endLoading')
        })
    },

    fetchSalesData() {
      this.$store.dispatch('startLoading')
      request
        .post('stock/sale_quantity', {
          item_ids: this.getItemIds(),
          days: this.salesDays,
        })
        .then(({ data }) => {
          this.sales = data.data
        })
        .finally(() => {
          this.$store.dispatch('endLoading')
        })
    },

    togglePhoto() {
      this.showPhoto = !this.showPhoto
      Cookie.set('preference_show_item_photo', this.showPhoto, {
        expires: 90,
      })
    },

    getItemIds() {
      return _.map(this.items, function (item) {
        return item.item_id
      })
    },

    selectDate(range) {
      this.range = range
      this.fetchStockData()
    },

    searchItem(item_ids) {
      this.searched = true

      if (item_ids.length == 0) {
        this.items = []
        this.$store.dispatch('endLoading')
        return
      }

      this.searchedItemIds = item_ids
      this.fetchStockData(item_ids)
    },

    refreshItemFilter() {
      this.itemFilterKey += 1
    },

    cleanSearch() {
      this.searched = false
      this.searchedItemIds = []
      this.refreshItemFilter()
      this.fetchStockData()
    },

    setPage(firstRow, lastRow) {
      this.pageFirstRow = firstRow
      this.pageLastRow = lastRow
    },

    arrivalRecord(item) {
      this.recordType = 'arrival'
      this.recordItem = item
      this.showModal = true
    },

    refundRecord(item) {
      this.recordType = 'refund'
      this.recordItem = item
      this.showModal = true
    },

    closeModal() {
      this.recordType = ''
      this.recordItem = null
      this.showModal = false
    },

    queryParams() {
      return {
        item_ids: this.searchedItemIds,
        search_type: this.searchType,
        start_date: this.range.startDate.format('YYYY-MM-DD'),
        end_date: this.range.endDate.format('YYYY-MM-DD'),
        order_numbers: this.searchOrderNumbers,
      }
    },

    download() {
      this.disableDownloadButton = true

      // 4秒後恢復
      setTimeout(() => {
        this.disableDownloadButton = false
      }, 4000)

      const url = 'stock/consignment_stock_download'
      const query = qs.stringify(this.queryParams(), {
        arrayFormat: 'bracket',
      })

      location.href = `${url}?${query}`
    },

    formatDate(date) {
      if (!date || date === '0000-00-00' || date === null) return '-'
      return moment(date).format('YYYY/MM/DD')
    },

    isNearExpiry(date, days) {
      if (!date || date === '0000-00-00' || date === null) return false
      const expiryDate = moment(date)
      const daysUntilExpiry = expiryDate.diff(moment(), 'days')
      return daysUntilExpiry <= days && daysUntilExpiry >= 0
    },
  },

  components: {
    PageTitle,
    ItemFilter,
    DateRangePicker,
    NoSearchResult,
    Pagination,
    Modal,
    Records,
    FloatingHeadTable,
  },
}
</script>

<style lang="scss" scoped>
@import '~foundation-sites/scss/foundation';
@import '@/legacy/styles/_settings.scss';
@import '@/legacy/styles/_table.scss';
@import '@/legacy/styles/_tableSorting.scss';

table.table-unborder {
  .last-cell {
    border-right: 1px solid #d8d8d8;
  }

  tr.group-head > th {
    @extend .last-cell;

    &:last-child {
      border-right: 0;
    }
  }

  tr > td,
  th {
    &:nth-child(5),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(16) {
      @extend .last-cell;
    }
  }
}

.form {
  font-size: 0.875rem;

  div {
    display: inline-block;
    padding-right: 5px;

    &.form-text {
      color: #aab2c0;
    }
  }
}

.text-warning {
  color: #ffc107;
}

.text-danger {
  color: #dc3545;
}
</style>
